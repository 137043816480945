import DataTable from "../../components/DataTable/DataTable";
import {Tooltip} from "antd";


const APITaskListPage = () => {

    const columns = [
        {
            title: 'Event',
            dataIndex: 'event_text',
            key: 'event_text',
            width: '10%'
        },
        {
            title: 'Tenant',
            dataIndex: 'tenant_text',
            key: 'tenant_text',
            width: '10%'
        },
        {
            title: 'Request Data',
            dataIndex: 'request_data',
            key: 'request_data',
            width: '24%',
            ellipsis: true,
            render: (text) => (
                <Tooltip title={JSON.stringify(text)} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{JSON.stringify(text)}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
            width: '24%',
            ellipsis: true,
            render: (text) => (
                <Tooltip title={JSON.stringify(text)} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{JSON.stringify(text)}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Response Data',
            dataIndex: 'response_data',
            key: 'response_data',
            width: '24%',
            ellipsis: true,
            render: (text) => (
                <Tooltip title={JSON.stringify(text)} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{JSON.stringify(text)}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status_text',
            key: 'status_text',
            width: '8%',
            render: (text) => (
                <Tooltip title={text} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text}</div>
                </Tooltip>
            ),
        }
    ];

    const searchFields = [
        {
            name: 'event',
            type: 'input',
            label: 'Event',
            placeholder: 'Event'
        },
//        {
//            name: 'tenant',
//            type: 'select',
//            label: 'Tenant',
//            placeholder: 'Select Tenant',
//            extraProps: {
//                url: '/api/v1/tenants/combo/'
//            }
//        },
        {
            name: 'status',
            type: 'select',
            label: 'Status',
            placeholder: 'Select Status',
            extraProps: {
                url: '/api/v1/enums/TaskStatusChoices/combo/'
            }
        }
    ];

    const data = [
        // Provide your table data if not using URL
    ];

    return (
            <DataTable
                reload={true}
                pageTitle={'API Tasks'}
                columns={columns}
                url="/api/v1/api_service/api_tasks/"
                searchFields={searchFields}
                pagination={true}
                pageButtons={[

                ]}
            />
    );
};

export default APITaskListPage;