import DataTable from "../../components/DataTable/DataTable";
import {useState} from "react";
import http from "../../http";
import {Button, message, Space, Tooltip, Typography} from "antd";
import AuthAppAddPage from "./AuthAppAddPage";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";


const AuthAppListPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [reload, setReload] = useState(false);
    const [edit, setEdit] = useState(false);
    const [item, setItem] = useState(false);
    const handleOpenModal = (item, edit) => {
        if(edit){
            setEdit(true);
            setItem(item);
            setShowModal(true);
        }
        else {
            setEdit(false);
            setItem(null);
            setShowModal(true);

        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEdit(false);
        setItem(null);
    };
    const handleSuccessAdd = () =>{
        setShowModal(false);
        setEdit(false);
        setItem(null);
        setReload(!reload);
    }

    const handleDeleteClick = (item)=>{
        http.delete(`/api/v1/api_service/auth_apps/${item.id}/delete/`).then((resp)=>{
            setReload(!reload);
        }).catch((err)=>{
            if(err.response){
                message.error(err.response.data.message);
            }
        })
    }
    const renderEditIcon=(record)=>{
        // if(!record.status){
        //     return <Typography.Link onClick={()=>handleOpenModal(record, true)}><EditOutlined /></Typography.Link>
        // }
        return <Typography.Link style={{color:'grey'}} onClick={()=>message.error('Can\'t be edited this item')}><EditOutlined /></Typography.Link>
    }
    const columns = [
        {
            title: 'App Name',
            dataIndex: 'name',
            key: 'app_name',
            width: '20%'
        },
        {
            title: 'Client ID',
            dataIndex: 'client_id',
            key: 'client_id',
            width: '35%',
            ellipsis: true,
            render: (text) => (
                <Tooltip title={text} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Client Secret',
            dataIndex: 'client_secret',
            key: 'client_secret',
            width: '35%',
            ellipsis: true,
            render: (text) => (
                <Tooltip title={text} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            fixed:'right',
            width: '10%',
            align: 'center',
            render: (record) => (
                <Space size="middle">
                    {renderEditIcon(record)}
                </Space>
            )
        },
    ];

    const searchFields = [
        {
            name: 'name',
            type: 'input',
            label: 'Name',
            placeholder: 'App Name'
        }
    ];

    const data = [
        // Provide your table data if not using URL
    ];

    return (
        <>
            <DataTable
                reload={reload}
                pageTitle={'Auth Apps'}
                columns={columns}
                url="/api/v1/api_service/auth_apps/"
                searchFields={searchFields}
                pagination={true}
                pageButtons={[
                    <Button
                        type="primary"
                        shape={'circle'}
                        icon={<PlusOutlined/>}
                        style={{marginLeft: 16, background:'#377EF3'}}
                        onClick={()=>handleOpenModal()}
                    />
                ]}
            />
            <AuthAppAddPage
                visible={showModal}
                onClose={handleCloseModal}
                onSuccessSubmit={handleSuccessAdd}
                edit={edit}
                editItem={item}
                reload={reload}
            />
        </>
    );
};

export default AuthAppListPage;