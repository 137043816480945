import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";


const AuthAppAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, modalSize, reload})=>{
    const [initialValues, setInitialValues] = useState({});
    const config = {
        addUrl: '/api/v1/api_service/auth_apps/add/',
        editUrl: edit ? `/api/v1/api_service/auth_apps/${editItem?.id}/edit/` : '',
        dataUrl: edit? `/api/v1/api_service/auth_apps/${editItem?.id}/` : '',
        pageTitle: !edit ? 'Auth App Add Page' : `Edit ${editItem?.name || ''}`,
        colNo: 2,
        cancelButtonText: 'Cancel',
        saveButtonText: !edit ? 'Save' : 'Edit',
        addSuccessMessage: !edit ? 'Auth App Added Successfully' : 'Auth App Updated Successfully',
        modalSize: modalSize || 400,
        fields: [
            {
                name: 'name',
                label: 'App Name',
                placeholder: 'Enter App Name',
                isRequired: true,
                requiredMessage: 'Name is Required',
                type: 'input',
                colNo: 1,
                order: 1
            }
        ]
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/api_service/auth_apps/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    return <AddPageModal config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues}/>

}

export default AuthAppAddPage;