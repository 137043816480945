import { combineReducers } from 'redux';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_USER, SET_SPINNER, REMOVE_SPINNER, UPDATE_PERMISSIONS } from './actions/types';

const initialState = {
  isLoggedIn: false,
  isLoading: false,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PERMISSIONS:
       return {
            ...state,
            permissions:action.payload.permissions
       }
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user_id: action.payload.user_id,
        username: action.payload.username,
        is_superuser: action.payload.is_superuser,
        is_admin: action.payload.is_admin,
        permissions: action.payload.permissions,
        isLoggedIn: true,
        isLoading: false,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        user_id: null,
        is_superuser: null,
        is_admin: null,
        isLoggedIn: false,
        isLoading: false,
        permissions: [],
        error: action.payload.error,
      };
    case LOGOUT_USER:
      return {
        isLoggedIn: false,
        isLoading: false,
        error: null
      };
    case SET_SPINNER:
      return {
        ...state,
        spinning: true
      };
    case REMOVE_SPINNER:
      return {
        ...state,
        spinning: false
      }
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  auth: authReducer,
});

export default rootReducer;
