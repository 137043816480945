import DataTable from "../../components/DataTable/DataTable";
import {Tooltip, Space, Typography, message} from "antd";
import { CheckOutlined, CloseOutlined} from '@ant-design/icons';
import React, {useState} from "react";
import http from "../../http";
import {useTranslation} from "react-i18next";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";


const MediationRequestListPage = (props) => {
    const [reload, setReload] = useState(false);
    const { t } = useTranslation();

    const columns = [
        {
            title: t('mediationrequest_list_col1'),
            dataIndex: 'application_date',
            key: 'application_date',
            width: '9%',
            fixed: 'left',
        },
        {
            title: t('mediationrequest_list_col2'),
            dataIndex: 'constructor',
            key: 'company_name',
            render: (text) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.company_name}</div>
            ),
            width: '10%',
            fixed: 'left',
        },
        {
            title: t('mediationrequest_list_col3'),
            dataIndex: 'constructor',
            key: 'identifier',
            render: (text) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.identifier}</div>
            ),
            width: '9%'
        },
        {
            title: t('mediationrequest_list_col4'),
            dataIndex: 'constructor',
            key: 'contact_no',
            render: (text) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.contact_no}</div>
            ),
            width: '9%'
        },
        {
            title: t('mediationrequest_list_col5'),
            dataIndex: 'constructor',
            key: 'email',
            render: (text) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.email}</div>
            ),
            width: '9%'
        },
        {
            title: t('mediationrequest_list_col6'),
            dataIndex: 'constructor',
            key: 'contact_person',
            render: (text) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.full_name}</div>
            ),
            width: '10%'
        },
        {
            title: t('mediationrequest_list_col7'),
            dataIndex: 'status_text',
            key: 'status_text',
            width: '8%',
            fixed: 'right',
            render: (text) => (
                <Tooltip title={t(text)} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{t(text)}</div>
                </Tooltip>
            ),
        }
    ];

    // const searchFields = [
    //     {
    //         name: 'partner',
    //         type: 'select',
    //         label: t('Partner'),
    //         placeholder: t('select_placeholder'),
    //         extraProps: {
    //             url: '/api/v1/partners/combo/',
    //         }
    //     },
    //     {
    //         name: 'status',
    //         type: 'select',
    //         label: t('mediationrequest_list_col7'),
    //         placeholder: t('select_placeholder'),
    //         extraProps: {
    //             url: '/api/v1/enums/MediationRequestStatusChoices/combo/',
    //             withTranslation: true
    //         }
    //     }
    // ];

    return (
            <DataTable
                reload={reload}
                pageTitle={props.type === 'mediation' ? t('partner_detail_mediation_requestlist_title'): t('partner_detail_mediation_cancel_requestlist_title')}
                columns={columns}
                url={props.type === 'mediation' ? `/api/v1/partner_constructor_mediation/requests/?partner=${props.partner.id}`:`/api/v1/partner_constructor_mediation/cancel_requests/?partner=${props.partner.id}`}
                // searchFields={searchFields}
                defaultParams={{status: 1}}
                pagination={true}
                pageButtons={[

                ]}
            />
    );
};

export default MediationRequestListPage;