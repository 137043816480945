// NotificationProvider.jsx
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import http from './http'; // Adjust import path as per your project structure

const NotificationContext = React.createContext();

export const useNotificationContext = () => {
    return React.useContext(NotificationContext);
};

const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn); // Example selector

    useEffect(() => {
        let isMounted = true; // Flag to track component mounted status
        let interval = null; // Variable to hold interval reference

        const fetchNotifications = async () => {
            try {
                const response = await http.get('/api/v1/notifications/');
                const notificationsData = response.data;
                if (isMounted) {
                    setNotifications(notificationsData);
                }
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        const startPolling = () => {
            fetchNotifications();
            interval = setInterval(fetchNotifications, 300000); // Poll every 30 seconds
        };

        const stopPolling = () => {
            clearInterval(interval); // Clear interval on logout
        };

        // Handle initial start and stop based on isLoggedIn
        if (isLoggedIn) {
            startPolling();
        } else {
            stopPolling();
        }

        // Cleanup on unmount and when isLoggedIn changes
        return () => {
            clearInterval(interval); // Clean up interval on unmount or logout
            isMounted = false; // Set mounted flag to false on component unmount
        };
    }, [isLoggedIn]); // Include isLoggedIn in dependency array

    return (
        <NotificationContext.Provider value={{ notifications }}>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;
