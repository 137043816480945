import {Divider, Row, Col, Space, Table} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import http from "../../http";
import PartnerListPage from "../Partners/PartnerListPage";
import ConstructorListPage from "../Constructors/ConstructorListPage";
import {useTranslation} from "react-i18next";


const UserDetailPage=(props)=>{
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = location;
    const [user, setUser] = useState(state?.user);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [isValidState, setValidState] = useState(!!state?.user);

    const fetchUserData=()=>{
        http.get(`/api/v1/auth/users/${user?.id}/`).then((resp)=>{
            setLoading(false);
            setUser(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        if(!state){
            navigate('/content_not_found',{})
        }
        if(isValidState) {
            fetchUserData()
        }
    }, [state])

    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state.breadcrumbs){
            state.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{user.full_name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/users'}>{t('menu_4')}  </Link> >  {user.full_name}</>;
    }
    if(isValidState) {
        return (
            <>
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: "black", fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col style={{lineHeight: '1.5rem'}}><h1>{user.full_name}</h1></Col>
                        <Col>{t('staff_username_field')} : {user.username}</Col>
                        <Col>{t('staff_email_field')} : {user.email}</Col>
                        <Col>{t('staff_telephone_field')} : {user.mobile}</Col>
                        <Col>{t('staff_slackid_field')} : {''}</Col>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden", height: '50%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <PartnerListPage pageSize={6} user={user} title={t('user_managed_partner_list_title')}/>
                        </div>
                    </Row>
                    {/*<Divider />*/}
                    {/*<Row style={{overflow: "hidden", height: '50%'}}>*/}
                    {/*    <div style={{width: '100%', height: '100%'}}>*/}
                    {/*        <ConstructorListPage user={user}/>*/}
                    {/*    </div>*/}
                    {/*</Row>*/}
                </div>
            </>

        )
    }
    else{
        return null;
    }
}

export default UserDetailPage;
