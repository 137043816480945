import HomePage from "./pages/HomePage/HomePage";
import PermissionDenied from "./PermissionDenied";
import LoginPage from "./pages/LoginPage/LoginPage";
import UserListPage from "./pages/Users/UserListPage";
import UserDetailPage from "./pages/Users/UserDetailPage";
import UserPermission from "./pages/Users/UserPermission";
import BranchListPage from "./pages/Branches/BranchListPage";
import ConstructorListPage from "./pages/Constructors/ConstructorListPage";
import ConstructorDetailPage from "./pages/Constructors/ConstructorDetailPage";
import PartnerListPage from "./pages/Partners/PartnerListPage";
import PartnerDetailPage from "./pages/Partners/PartnerDetailPage";
import ContentNotFound from "./ContentNotFound";
import AuthAppListPage from "./pages/AuthApps/AuthAppListPage";
import APIServerListPage from "./pages/APIServers/APIServerListPage";
import APIEventListPage from "./pages/APIEvents/APIEventListPage";
import APITaskListPage from "./pages/APITasks/APITaskListPage";
import SystemInfoListPage from "./pages/SystemInfos/SystemInfoListPage";
import UserProfilePage from "./pages/Users/UserProfile";


const routes = [
    {
        name: 'Login',
        path: '/login',
        exact: true,
        component: LoginPage,
        requiredPermission: null
    },
    {
        name: 'Home',
        path: '/',
        exact: true,
        component: UserProfilePage,
        requiredPermission: ['user-detail']
    },
    {
        name: 'Constructors',
        path: '/constructors',
        exact: true,
        component: ConstructorListPage,
        requiredPermission: ['constructor-list']
    },
    {
        name: 'Constructor Detail',
        path: '/constructor/detail/',
        exact: true,
        component: ConstructorDetailPage,
        requiredPermission: ['constructor-detail']
    },
    {
        name: 'Users',
        path: '/users',
        exact: true,
        component: UserListPage,
        requiredPermission: ['user-list']
    },
    {
        name: 'User Detail',
        path: '/user/detail',
        exact: true,
        component: UserDetailPage,
        requiredPermission: ['user-detail']
    },
    {
        name: 'User Permission',
        path: '/user_permissions',
        exact: true,
        component: UserPermission,
        requiredPermission: ['system_urls']
    },
    {
        name: 'Branch List',
        path: '/branches',
        exact: true,
        component: BranchListPage,
        requiredPermission: ['branch-list']
    },
    {
        name: 'Partner List',
        path: '/partners',
        exact: true,
        component: PartnerListPage,
        requiredPermission: ['partners-list']
    },
    {
        name: 'Partner Detail',
        path: '/partner/detail/',
        exact: true,
        component: PartnerDetailPage,
        requiredPermission: ['partner-detail']
    },
    {
        name: 'Auth Apps',
        path: '/auth_apps/',
        exact: true,
        component: AuthAppListPage,
        requiredPermission: ['auth-apps-list']
    },
    {
        name: 'API Servers',
        path: '/api_servers/',
        exact: true,
        component: APIServerListPage,
        requiredPermission: ['api-servers-list']
    },
    {
        name: 'API Events',
        path: '/api_events/',
        exact: true,
        component: APIEventListPage,
        requiredPermission: ['api-events-list']
    },
    {
        name: 'API Tasks',
        path: '/api_tasks/',
        exact: true,
        component: APITaskListPage,
        requiredPermission: ['api-tasks-list']
    },
    {
        name: 'System Infos',
        path: '/system_infos/',
        exact: true,
        component: SystemInfoListPage,
        requiredPermission: ['system_info']
    },
    {
        name: 'PermissionDenied',
        path: '/permission_denied',
        exact: true,
        component: PermissionDenied
    },
    {
        name: 'Content Not Found',
        path: '/content_not_found',
        exact: true,
        component: ContentNotFound
    },
    {
        name: "User Profile",
        path: '/user/profile',
        exact: true,
        component: UserProfilePage,
        requiredPermission: ['user-detail']
    }
]

export default routes;
