import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import http from "../../http";
import BranchAddPage from "./BranchAddPage";
import {message} from "antd";
import {useTranslation} from "react-i18next";


const BranchListPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(false);
  const { t } = useTranslation();
  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

  const buildCardBody = (data)=>{
    return null;
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return null;
  }

  const config = {
    url: '/api/v1/auth/branches/',
    pageTitle: t('branch_list_title'),
    pageSubtitle: t('branch_list_subtitle'),
    deleteTitle: t('branch_delete_confirmation_text'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {'address1': t('branch_address1_field'), 'address2': t('branch_address2_field'), 'contact_no': t('branch_contact_field'), 'parent_branch_name': t('branch_parent_branch_field')},
    cardFooterFields: [],
        orderingFields: [
            {id: '-name', name: t('branch_orderby_field1')},
            {id: 'name', name: t('branch_orderby_field2')},
        ],
    addPermission: ['branch-create'],
    editPermission: ['branch-edit'],
    deletePermission: ['branch-delete'],
    viewPermission: ['branch-detail'],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
  }
  const handleOpenModal = (item, edit) => {
    if(edit){
      setEdit(true);
      setItem(item);
      setShowModal(true);
    }
    else {
      setEdit(false);
      setItem(null);
      setShowModal(true);

    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEdit(false);
    setItem(null);
  };
  const handleSuccessAdd = () =>{
    setShowModal(false);
    setEdit(false);
    setItem(null);
    setReload(!reload);
  }

  const handleDeleteClick = (item)=>{
    http.delete(`/api/v1/auth/branches/${item.id}/delete/`).then((resp)=>{
      setReload(!reload);
    }).catch((err)=>{
      if(err.response){
        message.error(err.response.data.message);
      }
    })
  }
  return (
      <>
        <ListPage
            config={config}
            onAddClick={handleOpenModal}
            onEditClick={(item)=>handleOpenModal(item, true)}
            onDeleteClick={(item)=>handleDeleteClick(item)}
            reload={reload}
        />
        <BranchAddPage
            visible={showModal}
            onClose={handleCloseModal}
            onSuccessSubmit={handleSuccessAdd}
            edit={edit}
            editItem={item}
            reload={reload}
        />
      </>
  );
};

export default BranchListPage;
