import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";


const APIEventAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, modalSize, reload})=>{
    const [initialValues, setInitialValues] = useState({});
    const config = {
        addUrl: '/api/v1/api_service/api_events/add/',
        editUrl: edit ? `/api/v1/api_service/api_events/${editItem?.id}/edit/` : '',
        dataUrl: edit? `/api/v1/api_service/api_events/${editItem?.id}/` : '',
        pageTitle: !edit ? 'API Event Add Page' : `Edit ${editItem?.name || ''}`,
        colNo: 2,
        cancelButtonText: 'Cancel',
        saveButtonText: !edit ? 'Save' : 'Edit',
        addSuccessMessage: !edit ? 'API Event Added Successfully' : 'API Event Updated Successfully',
        modalSize: modalSize || 800,
        fields: [
            {
                name: 'name',
                label: 'Event Name',
                placeholder: 'Enter Event Name',
                isRequired: true,
                requiredMessage: 'Name is Required',
                type: 'input',
                colNo: 1,
                order: 1
            },
            {
                name: 'server',
                label: 'Server',
                placeholder: 'Select Server',
                isRequired: true,
                requiredMessage: 'Server is required',
                type: 'select',
                extraProps:{
                    url: '/api/v1/api_service/api_servers/combo/'
                },
                colNo: 1,
                order: 2
            },
            {
                name: 'request_method',
                label: 'Request Method',
                placeholder: 'Enter Request Method',
                isRequired: false,
                requiredMessage: '',
                type: 'select',
                extraProps: {
                    data: [{id: 'GET', name: 'GET'}, {id: 'POST', name: 'POST'}]
                },
                colNo: 1,
                order: 3
            },
            {
                name: 'file_name',
                label: 'Request Mapping File',
                placeholder: 'Request Mapping File Name',
                isRequired: false,
                requiredMessage: '',
                type: 'input',
                colNo: 1,
                order: 4
            },
            {
                name: 'url',
                label: 'Endpoint',
                placeholder: 'Enter Request URL',
                isRequired: true,
                requiredMessage: 'Request URL is required',
                type: 'input',
                colNo: 1,
                order: 5
            },
            {
                name: 'request_mapping',
                label: 'Request Mapping',
                placeholder: 'Request Mapping (JSON object)',
                isRequired: false,
                requiredMessage: '',
                type: 'textarea',
                extraProps: {
                    rows: 2
                },
                colNo: 2,
                order: 1
            },
            {
                name: 'response_mapping',
                label: 'Response Mapping',
                placeholder: 'Response Mapping (JSON object)',
                isRequired: false,
                requiredMessage: '',
                type: 'textarea',
                extraProps: {
                    rows: 2
                },
                colNo: 2,
                order: 2
            },
            {
                name: 'is_background',
                label: 'Background',
                placeholder: '',
                isRequired: false,
                requiredMessage: '',
                type: 'checkbox',
                colNo: 1,
                order: 6
            }
        ]
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/api_service/api_events/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
            resp.data.request_mapping =  JSON.stringify(resp.data.request_mapping)
             resp.data.response_mapping =  JSON.stringify(resp.data.response_mapping)
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    return <AddPageModal config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues}/>

}

export default APIEventAddPage;