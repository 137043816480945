import i18n from "../i18n";

function changeLanguage(lang){
    localStorage.setItem('i18next_lang', lang)
    i18n.changeLanguage(lang).then(() => {
        cacheLanguage()
    })
}

function cacheLanguage(){
    const currentLang = localStorage.getItem('i18next_lang')
    const translation = i18n.getDataByLanguage(currentLang)
    localStorage.setItem(`i18next_lang_${currentLang}`, JSON.stringify(translation["translation"]))
}

export { changeLanguage, cacheLanguage }