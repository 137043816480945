import DataTable from "../../components/DataTable/DataTable";
import {Tooltip, Space, Typography, message, Button} from "antd";
import { CheckOutlined, CloseOutlined} from '@ant-design/icons';
import React, {useState} from "react";
import http from "../../http";
import {useTranslation} from "react-i18next";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";


const PendingLicenseApplicationsListPage = (props) => {
    const [reload, setReload] = useState(false);
    const { t } = useTranslation();

    const handleLicenseApplicationApprove=(record)=>{
        if(record.status === 1 || record.status ===  4)(
            http.post(`/api/v1/machine_license/${record.id}/2/update_status/`).then((resp)=>{
                if(resp.status ===  200){
                    message.success(t('license_application_approve_successful_text'));
                    setReload(!reload);
                    props.handleLicenseApplicationApprove()
                }
                else{
                    message.error(t('license_application_approve_fail_text'))
                }
            }).catch((err)=>{
                message.error(t('license_application_approve_fail_text'))
            })
        )
    }
    const renderApproveIcon=(record)=>{
        if(record.status === 1 || record.status ===  4){
            return <CustomConfirmPopup
                title={t('license_application_approve_confirmation_text')}
                onConfirm={()=>handleLicenseApplicationApprove(record)}
                icon={<CheckOutlined style={{color: '#079327'}}/>}
                okText={t('yes_text')}
                cancelText={t('no_text')}
                okType='default'
            >
                <Button type={'primary'} className={'primary-button'}>{t('license_request_approve_button_text')}</Button>
            </CustomConfirmPopup>
        }
        return null;
    }
    const renderRejectIcon=(record)=>{

        return <Typography.Link style={{color:'#DC000C'}} onClick={()=>message.error(t('not_implemented_error_text'))}><CloseOutlined /></Typography.Link>
    }

    const columns = [
        {
            title: t('license_application_col1'),
            dataIndex: 'entry_date',
            key: 'entry_date',
        },
        {
            title: t('license_application_col2'),
            dataIndex: 'machine_data',
            key: 'machine_name',
            render: (text) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.name}</div>
            ),
        },
        {
            title: t('license_application_col7'),
            dataIndex: 'machine_data',
            key: 'machine_name',
            render: (text) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.number}</div>
            ),
        },
        {
            title: t('license_application_col8'),
            dataIndex: 'machine_data',
            key: 'machine_name',
            render: (text) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.manufacture}</div>
            ),
        },
        {
            title: t('license_application_col3'),
            dataIndex: 'machine_type_text',
            key: 'machine_type',
            render: (text) => (

                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{t(text)}</div>
            ),
        },
        {
            title: t('license_application_col4'),
            dataIndex: 'machine_identifier',
            key: 'machine_identifier',
        },
        {
            title: t('license_application_col5'),
            dataIndex: 'status_text',
            key: 'status_text',
            render: (text) => (
                <Tooltip title={t(text)} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{t(text)}</div>
                </Tooltip>
            ),
        },
        {
            title: t('license_application_col6'),
            key: 'action',
            fixed:'right',
            render: (record) => (
                <Space size="middle">
                    {renderApproveIcon(record)}
                </Space>
            )
        },
    ];

    const getRowClassName=(record, index)=>{
        return 'highlight-datatable-row-yellow'
    }

    return (
        <DataTable
            reload={reload}
            pageTitle={t('partner_detail_machine_license_applicationlist_title')}
            columns={columns}
            url={`/api/v1/machine_license/?partner=${props.partner.id}&status=1`}
            defaultParams={{status: 1}}
            pagination={true}
            pageButtons={[

            ]}
            hideHeader={true}
            rowClassName={getRowClassName}
        />
    );
};

export default PendingLicenseApplicationsListPage;