import axios from "axios";
import store from './store'
import {logout, removeSpinner, setSpinner} from "./actions/actions";

const http = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true
});

// Add a request interceptor
//http.interceptors.request.use(
//    (config) => {
//        const token = localStorage.getItem('jwtToken');
//        if (token) {
//            config.headers.Authorization = `Bearer ${token}`;
//        }
//        return config;
//    },
//    (error) => Promise.reject(error)
//);

// Add a response interceptor
let refreshTokenPromise = null;  // Variable to track the ongoing refresh token request
let failedQueue = [];            // Queue to store requests that fail with 401

// Add a response interceptor
http.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // If the error status is 401 and there is no originalRequest._retry flag
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            store.dispatch(setSpinner());

            // Create a promise to track the refresh token request
            const retryOriginalRequest = new Promise((resolve, reject) => {
                // Add to the queue if a refresh token request is already in progress
                failedQueue.push({ resolve, reject });

                // If no refresh token request is in progress
                if (!refreshTokenPromise) {
                    // Create the refresh token request
                    refreshTokenPromise = axios.post('/o/refresh_token/', { grant_type: 'refresh_token' }, {
                        baseURL: process.env.REACT_APP_BACKEND_URL,
                        withCredentials: true
                    })
                    .then(response => {
                        if (response.status === 200) {
                            // Resolve all requests in the queue
                            failedQueue.forEach(({ resolve }) => resolve(http(originalRequest)));
                            failedQueue = [];  // Clear the queue
                        } else {
                            store.dispatch(logout());
                            window.location.href = '/login';  // Redirect to login page
                        }
                    })
                    .catch(error => {
                        // Handle refresh token error
                        store.dispatch(logout());
                        window.location.href = '/login';  // Redirect to login page

                        // Reject all requests in the queue
                        failedQueue.forEach(({ reject }) => reject(error));
                        failedQueue = [];  // Clear the queue
                    })
                    .finally(() => {
                        store.dispatch(removeSpinner());
                        refreshTokenPromise = null;  // Reset the promise
                    });
                } else {
                    // If a refresh token request is already in progress, wait for it to complete
                    refreshTokenPromise
                        .then(() => resolve(http(originalRequest)))
                        .catch((err) => reject(err)).finally(()=> store.dispatch(removeSpinner()));
                }
            });

            return retryOriginalRequest;
        }

        // Handle errors not related to token refresh
        return Promise.reject(error);
    }
);

export default http;
