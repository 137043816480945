// DataContext.js
import { createContext, useContext, useState } from 'react';

const DataContext = createContext({});

export function useData() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {
  const [sharedData, setSharedData] = useState({});

  const setData = (data) => {
    setSharedData(data);
  };

  return (
    <DataContext.Provider value={{ sharedData, setData }}>
      {children}
    </DataContext.Provider>
  );
}
