import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";
import GroupAddPage from "./GroupAddPage";
import RoleAddPage from "./RoleAddPage";


const GroupRoleAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, modalSize})=>{
    const [initialValues, setInitialValues] = useState({})
    const [reload, setReload] = useState(false);
    const [groupModalVisible, setGroupModalVisible] = useState(false)
    const [roleModalVisible, setRoleModalVisible] = useState(false)
    const handleGroupAddClick=(field)=>{
        setGroupModalVisible(true)
    }
    const handleRoleAddClick=(field)=>{
        setRoleModalVisible(true)
    }
    const handleCloseGroupModal = () => {
    setGroupModalVisible(false);
  };
  const handleSuccessGroupAdd = () =>{
    setGroupModalVisible(false);
    setReload(!reload);
  }
  const handleCloseRoleModal = () => {
    setRoleModalVisible(false);
  };
  const handleSuccessRoleAdd = () =>{
    setRoleModalVisible(false);
    setReload(!reload);
  }
    const config = {
        addUrl: '/api/v1/auth/group_roles/add/',
        editUrl: edit ? `/api/v1/auth/group_roles/${editItem?.id}/edit/` : '',
        dataUrl: edit? `/api/v1/auth/group_roles/${editItem?.id}/` : '',
        pageTitle: !edit ? 'Group-Role Add Page' : `Edit ${editItem?.name || ''}`,
        colNo: 2,
        cancelButtonText: 'Cancel',
        saveButtonText: !edit ? 'Save' : 'Edit',
        addSuccessMessage: !edit ? 'Group-Role Added Successfully' : 'Group-Role Updated Successfully',
        modalSize: modalSize || 500,
        fields: [
            {
                name: 'group',
                label: 'Group',
                placeholder: 'Enter Group Name',
                isRequired: true,
                requiredMessage: 'Group Selection Mandatory',
                type: 'select',
                quickAdd: true,
                handleQuickAddClick: handleGroupAddClick,
                extraProps:{
                    url: '/api/v1/auth/groups/combo/'
                },
                colNo: 1,
                order: 1
            },
            {
                name: 'role',
                label: 'Role',
                placeholder: 'Enter Role Name',
                isRequired: true,
                requiredMessage: 'Role Selection Mandatory',
                type: 'select',
                quickAdd: true,
                handleQuickAddClick: handleRoleAddClick,
                extraProps:{
                    url: '/api/v1/auth/roles/combo/'
                },
                colNo: 1,
                order: 2
            }
        ]
    }


    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/auth/group_roles/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])
    return <>
        <GroupAddPage
            visible={groupModalVisible}
            onClose={handleCloseGroupModal}
            onSuccessSubmit={handleSuccessGroupAdd}
            reload={reload}
        />
        <RoleAddPage
            visible={roleModalVisible}
            onClose={handleCloseRoleModal}
            onSuccessSubmit={handleSuccessRoleAdd}
            reload={reload}
        />
        <AddPageModal config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} initialValues={initialValues}/>
        </>

}

export default GroupRoleAddPage;