import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";
import {useTranslation} from "react-i18next";


const BranchAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, modalSize, reload})=>{
    const [initialValues, setInitialValues] = useState({});
    const { t } = useTranslation();
    const config = {
        addUrl: '/api/v1/auth/branches/add/',
        editUrl: edit ? `/api/v1/auth/branches/${editItem?.id}/edit/` : '',
        dataUrl: edit? `/api/v1/auth/branches/${editItem?.id}/` : '',
        pageTitle: !edit ? t('branch_addpage_title') : t('addpage_edit_button_text') + ' ' +`${editItem?.name || ''}`,
        colNo: 2,
        cancelButtonText: t('addpage_cancel_button_text'),
        saveButtonText: !edit ? t('addpage_save_button_text') : t('addpage_edit_button_text'),
        addSuccessMessage: !edit ? t('branch_add_successful_message') : t('branch_edit_successful_message'),
        modalSize: modalSize || 400,
        fields: [
            {
                name: 'name',
                label: t('branch_name_field'),
                placeholder: t('branch_name_field_placeholder'),
                isRequired: true,
                requiredMessage: t('branch_name_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 1
            },
            {
                name: 'address1',
                label: t('branch_address1_field'),
                placeholder: t('branch_address1_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'input',
                colNo: 1,
                order: 2
            },
            {
                name: 'address2',
                label: t('branch_address2_field'),
                placeholder: t('branch_address2_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'input',
                colNo: 1,
                order: 3
            },
            {
                name: 'contact_no',
                label: t('branch_contact_field'),
                placeholder: t('branch_contactno_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'input',
                colNo: 1,
                order: 4
            },
            {
                name: 'parent',
                label: t('branch_parent_branch_field'),
                placeholder: t('select_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'select',
                colNo: 1,
                order: 5,
                extraProps:{
                    url: edit? `/api/v1/auth/branches/combo/?exclude_branch=${editItem?.id}` : '/api/v1/auth/branches/combo/'
                }
            }
        ]
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/auth/branches/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    return <AddPageModal config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues}/>

}

export default BranchAddPage;