import DataTable from "../../components/DataTable/DataTable";
import {Tooltip, Space, Typography, message, Button} from "antd";
import { CheckOutlined, CloseOutlined} from '@ant-design/icons';
import React, {useState} from "react";
import http from "../../http";
import {useTranslation} from "react-i18next";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";


const PendingMediationRequestListPage = (props) => {
    const [reload, setReload] = useState(false);
    const { t } = useTranslation();
    const handleApproveClick=(record)=>{
        if(record.status === 0) {
            let url = props.type === 'mediation' ?`/api/v1/partner_constructor_mediation/requests/${record.id}/1/approve/` : `/api/v1/partner_constructor_mediation/cancel_requests/${record.id}/1/approve/`;
            http.post(url).then((resp) => {
                if (resp.status === 200) {
                    message.success(props.type === 'mediation'? t('mediation_request_approve_successful_message'):t('mediation_cancelrequest_approve_successful_message'));
                    setReload(!reload);
                    props.handleMediationApplicationApprove()
                } else {
                    message.error(t('mediation_request_approve_fail_message'))
                }
            }).catch((err) => {
                message.error(t('mediation_request_approve_fail_message'))
            })
        }
    }
    const renderApproveIcon=(record)=>{
        if(record.status === 0){
            return <CustomConfirmPopup
                title={t('mediation_approve_confirmation_text')}
                onConfirm={()=>handleApproveClick(record)}
                icon={<CheckOutlined style={{color: '#079327'}}/>}
                okText={t('yes_text')}
                cancelText={t('no_text')}
                okType='default'
            >
                <Button type={'primary'} className={'primary-button'}>{t('mediation_request_approve_button_text')}</Button>
            </CustomConfirmPopup>
        }
        else{
            return null;
        }
    }
    const renderRejectIcon=(record)=>{
        if(record.status === 0) {
            return <Typography.Link style={{color: '#DC000C'}}
                                    onClick={() => message.error(t('not_implemented_error_text'))}><CloseOutlined/></Typography.Link>
        }
        else{
            return null
        }
    }

    const columns = [
        {
            title: t('mediationrequest_list_col1'),
            dataIndex: 'application_date',
            key: 'application_date',
        },
        {
            title: t('mediationrequest_list_col2'),
            dataIndex: 'constructor',
            key: 'company_name',
            render: (text) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.company_name}</div>
            ),
        },
        {
            title: t('mediationrequest_list_col3'),
            dataIndex: 'constructor',
            key: 'identifier',
            render: (text) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.identifier}</div>
            ),
        },
        {
            title: t('mediationrequest_list_col4'),
            dataIndex: 'constructor',
            key: 'contact_no',
            render: (text) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.contact_no}</div>
            ),
        },
        {
            title: t('mediationrequest_list_col5'),
            dataIndex: 'constructor',
            key: 'email',
            render: (text) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.email}</div>
            ),
        },
        {
            title: t('mediationrequest_list_col6'),
            dataIndex: 'constructor',
            key: 'contact_person',
            render: (text) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.full_name}</div>
            ),
            width: '10%'
        },
        {
            title: t('mediationrequest_list_col7'),
            dataIndex: 'status_text',
            key: 'status_text',
            fixed: 'right',
            render: (text) => (
                <Tooltip title={t(text)} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{t(text)}</div>
                </Tooltip>
            ),
        },
        {
            title: t('mediationrequest_list_col8'),
            key: 'action',
            fixed:'right',
            render: (record) => (
                <Space size="middle">
                    {renderApproveIcon(record)}
                    {/*{renderRejectIcon(record)}*/}
                </Space>
            )
        },
    ];

    const getRowClassName=(record, index)=>{
        return 'highlight-datatable-row-yellow'
    }

    return (
        <DataTable
            reload={reload}
            pageTitle={props.type === 'mediation' ? t('partner_detail_mediation_requestlist_title'): t('partner_detail_mediation_cancel_requestlist_title')}
            columns={columns}
            url={props.type === 'mediation' ? `/api/v1/partner_constructor_mediation/requests/?partner=${props.partner.id}&status=0`:`/api/v1/partner_constructor_mediation/cancel_requests/?partner=${props.partner.id}&status=0`}
            defaultParams={{status: 0}}
            pagination={true}
            pageButtons={[

            ]}
            hideHeader={true}
            rowClassName={getRowClassName}
        />
    );
};

export default PendingMediationRequestListPage;