import DataTable from "../../components/DataTable/DataTable";
import {useState} from "react";
import http from "../../http";
import {Button, message, Space, Tooltip, Typography} from "antd";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";


const LeaseRecordHistoryListPage = (props) => {
    const { t } = useTranslation();

    const columns = [
        {
            title:t('lease_recorddetail_col1'),
            dataIndex: 'start_date',
            key: 'start_date',
            width: '10%'
        },
        {
            title: t('lease_recorddetail_col2'),
            dataIndex: 'end_date',
            key: 'end_date',
            width: '10%'
        },
//        {
//            title: 'Machine Data',
//            dataIndex: 'machine_data',
//            key: 'machine_data',
//            render: (text) => (
//                     <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text? JSON.stringify(text): ''}</div>
//             ),
//             width: '50%'
//        },
        {
            title: t('lease_recorddetail_col3'),
            dataIndex: 'status_text',
            key: 'status_text',
            render: (text)=>(
                <div>{t(text)}</div>
            ),
            width: '10%'
        },
        {
            title: t('lease_recorddetail_col4'),
            dataIndex: 'description',
            key: 'description',
            render: (text)=>(
                <div>{t(text)}</div>
            ),
            width: '20%'
        }
    ];

    const searchFields = [

    ];

    const data = [
        // Provide your table data if not using URL
    ];

    return (
            <DataTable
                reload={props.loading}
                hideHeader = {true}
                pageTitle={props.title || 'Lease Record Histories'}
                columns={columns}
                url={props.url || "/api/v1/partner_constructor_mediation/lease_record_histories/"}
                searchFields={searchFields}
                pagination={true}
                pageButtons={[]}
            />

    );
};

export default LeaseRecordHistoryListPage;