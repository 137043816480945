import React, { useState, useEffect } from 'react';
import {Table, Input, DatePicker, Select, Button, Row, Col, Space} from 'antd';
import http from '../../http';
import classes from './DataTable.module.css';
import SelectField from "../Fields/SelectField";
import {useTranslation} from "react-i18next";

const { Option } = Select;
const ContentHeader = (props)=>{
    return(
        <Row justify="space-between" align="middle" key={'header'}>
            <Col key={'pageTitle'} span={14} style={{ flexDirection: 'row', display: 'flex', alignItems:'center'}}>
                <Space size={'middle'}>
                    <div style={{fontWeight: "bold", fontSize:"30px"}}>{props.title}</div>
                </Space>
            </Col>
            <Col>
                {props.pageButtons?.map(function(elem, index){
                    return elem;
                })}
            </Col>
        </Row>
    )
}
const SearchBox = ({ searchFields, onSearch, onReset }) => {
    const [searchValues, setSearchValues] = useState({});
    const { t } = useTranslation();
    const handleFieldChange = (fieldName, value) => {
        setSearchValues((prevValues) => ({
            ...prevValues,
            [fieldName]: value,
        }));
    };

    const handleSearch = () => {
        onSearch(searchValues);
    };

    const handleReset = () => {
        setSearchValues({});
        onReset();
    };

    return (
        searchFields && searchFields.length > 0 ? (<Row className={classes.searchForm}>
            {searchFields?.map((field) => (
                <div key={field.name} style={{marginLeft:'5px', marginRight: '5px'}}>
                    {field.type === 'input' && (
                        <Input
                            placeholder={field.placeholder}
                            value={searchValues[field.name] || ''}
                            onChange={(e) => handleFieldChange(field.name, e.target.value)}
                        />
                    )}
                    {field.type === 'date' && (
                        <DatePicker
                            placeholder={field.placeholder}
                            value={searchValues[field.name] || null}
                            onChange={(date) => handleFieldChange(field.name, date)}
                        />
                    )}
                    {field.type === 'select' && (
                        <SelectField placeholder={field.placeholder} {...field.extraProps} name={field.name} onChange={(value) => handleFieldChange(field.name, value)}/>
                    )}
                </div>
            ))}
            <Button type="primary" onClick={handleSearch} style={{marginLeft:'5px', marginRight: '5px'}}>
                {t('table_search_button_text')}
            </Button>
            <Button onClick={handleReset} style={{marginLeft:'5px', marginRight: '5px'}}>{t('table_reset_button_text')}</Button>
        </Row>) : null
    );
};

const DataTable = ({ defaultParams, pageTitle, columns, url, data, searchFields, pagination, pageButtons=[], reload, ...props }) => {
    const [dataSource, setDataSource] = useState([]);
    const [currentSearchValues, setSearchValues] = useState({});
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [paginationObject, setPagination]= useState({
        page: 1,
        page_size: 10
    })
    const { t } = useTranslation();
    const defaultPageSizes = [2, 10, 15, 20, 25, 50];
    const pageSizeOptions = props.pageSize ? [props.pageSize, ...defaultPageSizes.filter(size => size !== props.pageSize)] : defaultPageSizes;

    useEffect(() => {
        fetchData(currentSearchValues, paginationObject);
    }, [reload, data, paginationObject.page, paginationObject.page_size]);

    useEffect(()=>{
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    const fetchData = (searchValues, pagination) => {
        let params = {...defaultParams, ...paginationObject, ...currentSearchValues, ...searchValues, ...pagination}
        if (url) {
            http
                .get(url, {
                    params: params,
                })
                .then((response) => {
                    setDataSource(response.data.results);
                    let paginationData = {...paginationObject, total: response.data.count}
                    setPagination(paginationData)
                });
        } else {
            setDataSource(data);
        }
    };

    const handleSearch = (searchValues) => {
        let values = {...currentSearchValues, ...searchValues}
        setSearchValues(values)
        fetchData(values, paginationObject);
    };

    const handleReset = () => {
        setSearchValues({})
        fetchData({}, paginationObject);
    };

    const onPageChange = (page, pageSize)=>{
        setPagination({...paginationObject, page: page, page_size: pageSize})
        // fetchData(currentSearchValues, {...paginationObject, page: page})
    }

    // Adjust columns for better responsiveness
    const adjustedColumns = columns.map(col => ({
        ...col,
        onCell: () => ({
            style: {
                minWidth: 150, // Adjust this value as needed
            },
        }),
    }));

    const scrollConfig = {
        x: windowWidth < 768 ? 'max-content' : null,
        y: 'calc(100vh - 250px)',
    };

    const itemRender = (page, type, originalElement) => {
        if (type === 'page') {
            return (
                <span
                    className={`custom-page-item ${page === paginationObject.page ? 'current' : ''}`}
                >
          {page}
        </span>
            );
        }
        if (type === 'prev' || type === 'next') {
            return (
                <span className="custom-page-item">
          {originalElement}
        </span>
            );
        }
        return originalElement;
    };

    const onShowSizeChange = (current, pageSize) => {
        setPagination({...paginationObject, page_size: pageSize})
        localStorage.setItem(pageTitle, pageSize)
        // fetchData(currentSearchValues, );
    };

    // Determine row class based on props
    const getRowClassName = (record, index) => {
        if (props.rowClassName) {
            return props.rowClassName(record, index)
        }
        return '';
    };

    return (
        <div style={{height: '100%', width: '100%'}}>
            {!props.hideHeader ?<ContentHeader title={pageTitle} pageButtons={pageButtons}/>:null}
            <Row style={{justifyContent:'center'}}>
                <SearchBox
                    searchFields={searchFields}
                    onSearch={handleSearch}
                    onReset={handleReset}
                />
            </Row>
            <Row gutter={16} style={{height: '78%', marginTop:'10px', overflowY: 'auto'}} key={'card'}>
                <Table
                    locale={{ emptyText: t('table_no_data_text') }}
                    sticky={true}
                    columns={adjustedColumns}
                    dataSource={dataSource}
                    pagination={pagination? {
                        ...paginationObject,
                        onChange: (page, pageSize) =>onPageChange(page, pageSize),
                        itemRender:(page, type, elem) => itemRender(page, type, elem),
                        onShowSizeChange: (current, pageSize)=>onShowSizeChange(current, pageSize),
                        size:"small",
                        showSizeChanger: true,
                        position: ['bottomCenter'],
                        pageSizeOptions:pageSizeOptions,
                        defaultPageSize:localStorage.getItem(pageTitle) || props.pageSize || paginationObject.page_size
                    }: pagination}
                    expandable={{
                        expandedRowRender: props.extendedRowRender ? (record) => props.extendedRowRender(record) : undefined,
                        rowExpandable: props.rowExpandable ? (record) => props.rowExpandable(record) : undefined,
                    }}
                    scroll={scrollConfig}
                    rowClassName={getRowClassName}
                />
            </Row>
        </div>
    );
};

export default DataTable;
