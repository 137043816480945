import {Popconfirm, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export const CustomConfirmPopup=({ title, icon, okText, cancelText, disabled, okType, onConfirm, onCancel, children, ...restProps })=>{
    const { i18n } = useTranslation();
    const handleCancel=()=>{
        // Call the onCancel function with the provided arguments based on language
        if(i18n.language === 'ja') {
            if (typeof onConfirm === 'function') {
                onConfirm();
            }
        }
        else{
            if (typeof onCancel === 'function') {
                onCancel();
            }
        }
    }
    const handleConfirm=()=>{
        // Call the onConfirm function with the provided arguments based on language
        if(i18n.language === 'ja') {
            if (typeof onCancel === 'function') {
                onCancel();
            }
        }
        else{
            if (typeof onConfirm === 'function') {
                onConfirm();
            }
        }
    }
    return <Popconfirm
        title={title}
        onConfirm={handleConfirm}
        icon={icon}
        okText={i18n.language === 'ja' ? cancelText: okText}
        cancelText={i18n.language === 'ja'? okText: cancelText}
        disabled={disabled}
        okType={okType}
        onCancel={handleCancel}
        {...restProps}
    >
        {children}
    </Popconfirm>
}