import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import http from "../../http";
import {useNavigate} from "react-router-dom";
import {message} from "antd";
import {useTranslation} from "react-i18next";
import {Row} from 'antd'

const MachineListPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

  const buildCardBody = (data)=>{
        const cardBodyFields = {'number': t('machine_carnumber_field'), 'machine_type_text':  t('machine_type_field'), 'identifier': t('machine_identifier_field'), 'manufacture': t('machine_manufacturer_field')};
        let innerContents = [];
        if(cardBodyFields) {
            for (const key in cardBodyFields) {
                const nestedKeys = key.split(".");
                let value = data;
                for (const nestedKey of nestedKeys) {
                    value = value[nestedKey];
                    if (value === undefined) {
                        break;
                    }
                }
                if( key === 'machine_type_text'){
                    innerContents.push(<Row key={key}>{cardBodyFields[key]}: {t(value)}</Row>)
                }
                else{
                    innerContents.push(<Row key={key}>{cardBodyFields[key]}: {value}</Row>)
                }
            }
        }
        return <Row style={{flexDirection:'column', justifyContent:'start'}} key={'cardContent'}>

            {innerContents}

        </Row>
    }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return null;
  }
  const config = {
    pageSize: props.pageSize || 10,
    url: `/api/v1/partner_constructor_mediation/machines/?partner=${props.partner.id}`,
    hideEdit: true,
    disableDelete: true,
    hideAddButton: true,
    pageTitle: t('partner_detail_machine_list_title'),
    pageSubTitle: t('partner_detail_machine_list_subtitle'),
    deleteTitle: "Are you sure you want to delete this Machine?",
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'name',
    cardDetailButtonText:  t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {'machine_type_text': 'Machine Type', 'number': 'Car Number', 'model': 'Model', 'identifier': 'Identifier', 'manufacture': 'Manufacturer'},
    cardFooterFields: [],
  orderingFields: [
        {id: '-name', name: t('machine_orderby_field1')},
        {id: 'name', name: t('machine_orderby_field2')},
        {id: '-number', name: t('machine_orderby_field3')},
        {id: 'number', name: t('machine_orderby_field4')},
        {id: '-created', name: t('machine_orderby_field5')},
        {id: 'created', name: t('machine_orderby_field6')},
    ],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
  }

  const handleOpenModal = (item, edit) => {
    if(edit){
      setEdit(true);
      setItem(item);
      setShowModal(true);
    }
    else {
      setEdit(false);
      setItem(null);
      setShowModal(true);

    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEdit(false);
    setItem(null);
  };
  const handleSuccessAdd = () =>{
    setShowModal(false);
    setEdit(false);
    setItem(null);
    setReload(!reload);
  }

  const handleDeleteClick = (item)=>{
    http.delete(`/api/v1/machines/${item.id}/delete/`).then((resp)=>{
      setReload(!reload);
    })
  }

  const handleDetailClick=(item)=>{
    message.error(t('not_implemented_error_text'))
//    navigate('/machine/detail/', {state: {machine: item} })
  }

  return (
      <>
        <ListPage
            config={config}
            onAddClick={handleOpenModal}
            onEditClick={(item)=>handleOpenModal(item, true)}
            onDeleteClick={(item)=>handleDeleteClick(item)}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
      </>
  );
};

export default MachineListPage;
