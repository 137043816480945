import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import http from "../../http";
import {useNavigate} from "react-router-dom";
import {message} from "antd";
import {useTranslation} from "react-i18next";

const PartnerPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

  const buildCardBody = (data)=>{
    return null;
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return [<label style={{color:data.status === 1 ? '#079327' : '#DC000C'}}>{t(data.status_text)}</label>];
  }

  const config = {
    pageSize: props.pageSize || 10,
    url: `/api/v1/partner_constructor_mediation/?constructor=${props.constructor.id}`,
    hideEdit: true,
    disableDelete: true,
    hideAddButton: true,
    pageTitle: t('constructor_partner_list_title'),
    deleteTitle: 'Are you sure you want to delete this partner?',
    pageSubtitle: t('partner_list_subtitle'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'partner_name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {'partner.full_name': t('partner_contactperson_field'), 'partner.email': t('partner_addpage_email_field'), 'partner.contact_no': t('partner_addpage_contact_field')},
    cardFooterFields: [],
    orderingFields: [{id: '-company_name', name: t('partner_name_desc_order_field')}, {id: 'company_name', name: t('partner_name_asc_order_field')}],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
  }
  const handleOpenModal = (item, itemEdit) => {
    if(itemEdit){
      setEdit(true);
      setItem(item);
      setShowModal(true);
    }
    else {
      setEdit(false);
      setItem(null);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setEdit(false);
    setItem(null);
    setShowModal(false);
  };
  const handleSuccessAdd = () =>{
    setEdit(false);
    setItem(null);
    setReload(!reload);
    setShowModal(false);
  }

  const handleDeleteClick = (item)=>{
    // http.delete(`/api/v1/partners/${item.id}/delete/`).then((resp)=>{
    //   if(resp.status === 204) {
    //     setReload(!reload);
    //   }
    //   else{
    //     message.error('Partner Delete Failed')
    //   }
    // }).catch((err)=>{
    //   if(err.response){
    //     message.error(err.response.data.message);
    //   }
    // })
  }

  const handleDetailClick=(item)=>{
    navigate('/partner/detail/', { replace: false, state: {partner: item.partner} })
  }

  return (
      <>
        <ListPage
            config={config}
            onAddClick={handleOpenModal}
            onEditClick={(item)=>handleOpenModal(item, true)}
            onDeleteClick={(item)=>handleDeleteClick(item)}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />

      </>
  );
};

export default PartnerPage;
