import {Divider, Row, Col, Space} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import http from '../../http';
import PartnerListPage from "./Partners";
import ConstructorMachineListPage from "./MachineList"
import {useTranslation} from "react-i18next";


const ConstructorDetailPage=(props)=>{
    const location = useLocation();
    const {state} = location;
    const [constructor, setConstructor] = useState(state?.constructor);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isValidState, setValidState] = useState(!!state?.constructor);

    const fetchConstructorData=()=>{
        http.get(`/api/v1/constructors/${constructor?.id}/`).then((resp)=>{
            setLoading(false);
            setConstructor(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }


    useEffect(()=>{
        if(!state){
            navigate('/content_not_found',{replace: true})
        }
        if(isValidState) {
            fetchConstructorData();
        }
    }, [state])


    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state?.breadcrumbs){
            state?.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{constructor.company_name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/constructors'}>{t('menu_2')}  </Link> >  {constructor.company_name}</>;
    }
    if(isValidState) {
        return (
            <>
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: "black", fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col style={{lineHeight: '1.5rem'}}><h1>{constructor.company_name}</h1></Col>
                        <Col>
                            <Row style={{display: 'flex', flexDirection: 'row'}}>
                                <Col>
                                    <Col>{t('constructor_identifier_field')}: {constructor.identifier}</Col>
                                    <Col>{t('constructor_email_field')}: {constructor.email}</Col>
                                    <Col>{t('constructor_contact_field')}: {constructor.contact_no}</Col>
                                    <Col>{t('partner_addpage_address1_field')}: {constructor.address_1}</Col>
                                </Col>
                                <Divider type='vertical'/>
                                <Col>
                                    <Col>{t('constructor_contact_person_field')}: {constructor.full_name}</Col>
                                    <Col>{t('partner_addpage_remoteusername_field')}: {constructor.email}</Col>
                                    <Col>{t('partner_addpage_remotepassword_field')}: {constructor.remote_password}</Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden", height: '60%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <PartnerListPage pageSize={3} constructor={constructor} link={[{
                                to: '/constructors/',
                                state: {},
                                title: t('menu_2')
                            }, {
                                to: '/constructor/detail/',
                                state: {constructor: constructor},
                                title: constructor.company_name
                            }]}/>
                        </div>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden"}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <ConstructorMachineListPage pageSize={3} constructor={constructor} link={[{
                                to: '/constructors/',
                                state: {},
                                title: t('menu_2')
                            }, {
                                to: '/constructor/detail/',
                                state: {constructor: constructor},
                                title: constructor.company_name
                            }]}/>
                        </div>
                    </Row>
                </div>
            </>

        )
    }
    else{
        return null;
    }
}

export default ConstructorDetailPage;