import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import {useNavigate} from "react-router-dom";
import {message} from "antd";
import {useTranslation} from "react-i18next";

const ConstructorListPage = (props) => {
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

  const buildCardBody = (data)=>{
    return null;
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return [<label style={{color:data.status ? '#079327' : '#DC000C'}}>{t(data.status_text)}</label>];
  }

  const buildListUrl=()=>{
    if(props.user){
        return `/api/v1/constructors/?assigned_to=${props.user.id}`
    }
    return '/api/v1/constructors/';
  }

  const config = {
    url: buildListUrl(),
    pageTitle: t('constructor_list_title'),
    deleteTitle: 'Are you sure you want to delete this constructor?',
    pageSubtitle: t('constructor_list_subtitle'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'company_name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {'identifier': t('constructor_identifier_field'), 'full_name': t('constructor_contact_person_field'), 'email': t('constructor_email_field'), 'contact_no': t('constructor_contact_field')},
    cardFooterFields: [],
    orderingFields: [{id: '-company_name', name: t('constructor_name_desc_orderby_field')}, {id: 'company_name', name: t('constructor_name_asc_orderby_field')}],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
    hideEdit: true,
    disableDelete: true,
    hideAddButton: true,
  }

  const handleDetailClick=(item)=>{
    navigate('/constructor/detail/', { replace: true, state: {constructor: item} })
  }

  return (
      <>
        <ListPage
            config={config}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
      </>
  );
};

export default ConstructorListPage;
