import DataTable from "../../components/DataTable/DataTable";
import {Tooltip, Space, Typography, message} from "antd";
import { CheckOutlined, CloseOutlined} from '@ant-design/icons';
import React, {useState} from "react";
import http from "../../http";
import {useTranslation} from "react-i18next";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";


const LicenseApplicationsListPage = (props) => {
    const [reload, setReload] = useState(false);
    const { t } = useTranslation();

    const columns = [
        {
            title: t('license_application_col1'),
            dataIndex: 'entry_date',
            key: 'entry_date',
            width: '20%'
        },
        {
            title: t('license_application_col2'),
            dataIndex: 'machine_data',
            key: 'machine_name',
            width: '20%',
            render: (text) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text.name}</div>
            ),
        },
        {
            title: t('license_application_col3'),
            dataIndex: 'machine_type_text',
            key: 'machine_type',
            width: '20%',
            render: (text) => (

                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{t(text)}</div>
            ),
        },
        {
            title: t('license_application_col4'),
            dataIndex: 'machine_identifier',
            key: 'machine_identifier',
            width: '20%'
        },
        {
            title: t('license_application_col5'),
            dataIndex: 'status_text',
            key: 'status_text',
            width: '10%',
            render: (text) => (
                <Tooltip title={t(text)} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{t(text)}</div>
                </Tooltip>
            ),
        }
    ];

    // const searchFields = [
    //     {
    //         name: 'partner',
    //         type: 'select',
    //         label: t('Partner'),
    //         placeholder: t('select_placeholder'),
    //         extraProps: {
    //             url: '/api/v1/partners/combo/',
    //         }
    //     },
    //     {
    //         name: 'machine_identifier',
    //         type: 'input',
    //         label: t('machine_identifier_field'),
    //         placeholder: t('machine_identifier_field')
    //     },
    //
    //     {
    //         name: 'status',
    //         type: 'select',
    //         label: t('license_application_col5'),
    //         placeholder: t('select_placeholder'),
    //         extraProps: {
    //             url: '/api/v1/enums/MachineLicenseStatusChoices/combo/',
    //             withTranslation: true
    //         }
    //     }
    // ];


    return (
            <DataTable
                reload={reload}
                pageTitle={t('partner_detail_machine_license_applicationlist_title')}
                columns={columns}
                url={`/api/v1/machine_license/?partner=${props.partner.id}`}
                // searchFields={searchFields}
                defaultParams={{status: 2}}
                pagination={true}
                pageButtons={[

                ]}
            />
    );
};

export default LicenseApplicationsListPage;