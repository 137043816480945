import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";


const APIServerAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem, modalSize, reload})=>{
    const [initialValues, setInitialValues] = useState({});
    const config = {
        addUrl: '/api/v1/api_service/api_servers/add/',
        editUrl: edit ? `/api/v1/api_service/api_servers/${editItem?.id}/edit/` : '',
        dataUrl: edit? `/api/v1/api_service/api_servers/${editItem?.id}/` : '',
        pageTitle: !edit ? 'API Server Add Page' : `Edit ${editItem?.name || ''}`,
        colNo: 2,
        cancelButtonText: 'Cancel',
        saveButtonText: !edit ? 'Save' : 'Edit',
        addSuccessMessage: !edit ? 'API Server Added Successfully' : 'API Server Updated Successfully',
        modalSize: modalSize || 800,
        fields: [
            {
                name: 'name',
                label: 'Server Name',
                placeholder: 'Enter Server Name',
                isRequired: true,
                requiredMessage: 'Name is Required',
                type: 'input',
                colNo: 1,
                order: 1
            },
            {
                name: 'server_domain',
                label: 'Server Domain',
                placeholder: 'Enter Server Domain',
                isRequired: false,
                requiredMessage: '',
                type: 'input',
                colNo: 1,
                order: 2
            },
            {
                name: 'server_ip',
                label: 'Server IP',
                placeholder: 'Enter Server IP',
                isRequired: false,
                requiredMessage: '',
                type: 'input',
                colNo: 1,
                order: 3
            },
            {
                name: 'server_port',
                label: 'Server Port',
                placeholder: 'Enter Server Port',
                isRequired: true,
                requiredMessage: 'Port is required',
                type: 'input',
                colNo: 1,
                order: 4
            },
            {
                name: 'token_url',
                label: 'Token URL',
                placeholder: 'Enter Token URL',
                isRequired: true,
                requiredMessage: 'Token URL is required',
                type: 'input',
                colNo: 1,
                order: 5
            },
            {
                name: 'login_url',
                label: 'Login URL',
                placeholder: 'Enter Login URL',
                isRequired: true,
                requiredMessage: 'Login URL is required',
                type: 'input',
                colNo: 1,
                order: 6
            },
            {
                name: 'app_name',
                label: 'Auth APP',
                placeholder: 'Enter App Name',
                isRequired: true,
                requiredMessage: 'App name is required',
                type: 'input',
                colNo: 2,
                order: 1
            },
            {
                name: 'client_id',
                label: 'Client ID',
                placeholder: 'Enter Client ID',
                isRequired: true,
                requiredMessage: 'Client ID is required',
                type: 'input',
                colNo: 2,
                order: 2
            },
            {
                name: 'client_secret',
                label: 'Client Secret',
                placeholder: 'Enter Client Secret',
                isRequired: true,
                requiredMessage: 'Client Secret is required',
                type: 'input',
                colNo: 2,
                order: 3
            },
            {
                name: 'api_username',
                label: 'API Username',
                placeholder: 'Enter API Username',
                isRequired: true,
                requiredMessage: 'API Username is required',
                type: 'input',
                colNo: 2,
                order: 4
            },
            {
                name: 'api_password',
                label: 'API Password',
                placeholder: 'Enter API Password',
                isRequired: true,
                requiredMessage: 'API Password is required',
                type: 'input',
                colNo: 2,
                order: 5
            }
        ]
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/api_service/api_servers/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    return <AddPageModal config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues}/>

}

export default APIServerAddPage;