import {Col, List, Row} from "antd";
import {useEffect, useState} from "react";
import http from "../../http";


const SystemInfoListPage = () => {
    const [systemInfo, setSystemInfo] = useState([])

    useEffect(() => {
        http.get(`/api/v1/system_info/`).then((resp)=>{
            setSystemInfo(resp.data.system_info)
        }).catch(()=>{
            console.log("Something Wrong!")
        })
    }, [])

    const getDictData = () => {
        return [
            ['App Name: ', `${systemInfo.application_name}`],
            ['Version: ', `${systemInfo.version_number}`],
            ['Branch: ', `${systemInfo.branch_name}`],
            ['Current Commit: ', `${systemInfo.current_commit_hash + ' / ' + systemInfo.current_commit_author}`],
            ['Last Commit: ', `${systemInfo.last_commit_hash + ' / ' + systemInfo.last_commit_author}`],

        ]
    }

    return (
        <Row style={{display: 'flex', flexDirection:'column'}}>
            <Col style={{lineHeight: '1.5rem'}}><h1>System Info</h1></Col>
            <List
                size="large"
                bordered
                dataSource={getDictData()}
                renderItem={(item) => <List.Item>
                    <b>{item[0]}</b> {item[1]}
                </List.Item>}
            />
        </Row>
    );
};

export default SystemInfoListPage;
