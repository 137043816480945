import {Divider, Row, Col, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import http from "../../http";
import PartnerListPage from "../Partners/PartnerListPage";
import ConstructorListPage from "../Constructors/ConstructorListPage";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


const UserProfilePage=(props)=>{
    const auth = useSelector((state) => state.auth);
    const [user, setUser] = useState({id: auth.user_id});
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const fetchUserData=()=>{
        let userID = auth.user_id;
        http.get(`/api/v1/auth/users/${userID}/profile/`).then((resp)=>{
            setLoading(false);
            setUser(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        setLoading(true)
        fetchUserData()
    }, [])

    const buildBreadcrumbs=()=>{
        return t('menu_0');
    }

    return(
        <>
            <div style={{overflowY:'auto', overflowX:'hidden', width: '100%', height:'100%'}}>
                <Row style={{height: '5%', color: "black", fontWeight:'bold', justifyContent:'space-between'}}>
                    <Col>
                        <Space>{buildBreadcrumbs()}</Space>
                    </Col>
                </Row>
                <Row style={{display: 'flex', flexDirection:'column'}}>
                    <Col style={{lineHeight: '1.5rem'}}><h1>{user.full_name}</h1></Col>
                    <Col>{t('staff_email_field')} : {user.email}</Col>
                    <Col>{t('staff_telephone_field')} : {user.mobile}</Col>
                    <Col>{t('staff_slackid_field')} : {''}</Col>
                </Row>
                <Divider />
                <Row style={{overflow: "hidden", height: '50%'}}>
                    <div style={{width: '100%', height: '100%'}}>
                        <PartnerListPage pageSize={6} user={user} title={t('user_managed_partner_list_title')}/>
                    </div>
                </Row>
                {/*<Divider />*/}
                {/*<Row style={{overflow: "hidden", height: '50%'}}>*/}
                {/*    <div style={{width: '100%', height: '100%'}}>*/}
                {/*        <ConstructorListPage user={user}/>*/}
                {/*    </div>*/}
                {/*</Row>*/}
            </div>
        </>

    )
}

export default UserProfilePage;
